import React, { useState, useEffect, useContext } from "react";
import { useParams, useHistory } from "react-router-dom";

import { toast } from "react-toastify";
import openSocket from "socket.io-client";
import clsx from "clsx";

import { Paper, makeStyles } from "@material-ui/core";

import ContactDrawer from "../ContactDrawer";
import MessageInput from "../MessageInput/";
import TicketHeader from "../TicketHeader";
import TicketInfo from "../TicketInfo";
import TicketActionButtons from "../TicketActionButtons";
import MessagesList from "../MessagesList";
import api from "../../services/api";
import { ReplyMessageProvider } from "../../context/ReplyingMessage/ReplyingMessageContext";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import PermissionDenied from "./permissionDenied";


const drawerWidth = 320;

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		height: "100%",
		position: "relative",
		//              position: "absolute",
		overflow: "hidden",
	},

	mainWrapper: {
		flex: 1,
		height: "100%",
		display: "flex",
		flexDirection: "column",
		overflow: "hidden",
		borderTopLeftRadius: 0,
		borderBottomLeftRadius: 0,
		borderLeft: "0",
		marginRight: -drawerWidth,
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},

	mainWrapperShift: {
		borderTopRightRadius: 0,
		borderBottomRightRadius: 0,
		transition: theme.transitions.create("margin", {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: 0,
	},
	input: {
		position: "fixed"
	},
}));

const Ticket = () => {
	const { ticketId } = useParams();
	const history = useHistory();
	const classes = useStyles();

	const [drawerOpen, setDrawerOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [contact, setContact] = useState({});
	const [ticket, setTicket] = useState({});
	const [allowed, setAllowed] = useState(false);
	const { user } = useContext(AuthContext);
	let userIdLocal = "";
	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchTicket = async () => {
				try {
					const { data } = await api.get("/tickets/" + ticketId);
					setContact(data.contact);
					setTicket(data);
					userIdLocal = data?.userId;
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
				if ((userIdLocal == user.id || ticket.status == "pending") || user.profile == 'admin') {
					setAllowed(true);
				}
			};
			fetchTicket();

		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [ticketId, history]);
	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("connect", () => socket.emit("joinChatBox", ticketId));

		socket.on("ticket", data => {
			console.log('no ticket')
			if (data.action === "update") {
				setTicket(data.ticket);
			}

			if (data.action === "delete") {
				toast.success("Ticket deleted sucessfully.");
				history.push("/tickets");
			}
		});

		socket.on("contact", data => {
			console.log('no contact')
			if (data.action === "update") {
				setContact(prevState => {
					if (prevState.id === data.contact?.id) {
						return { ...prevState, ...data.contact };
					}
					return prevState;
				});
			}
		});
		if ((ticket.userId == user.id || ticket.status == "pending") || user.profile == 'admin') {
			setAllowed(true);
		}
		return () => {
			socket.disconnect();
		};
	}, [ticketId, history]);
	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	return (
		<div className={classes.root} id="drawer-container">
			<Paper
				variant="outlined"
				elevation={0}
				className={clsx(classes.mainWrapper, {
					[classes.mainWrapperShift]: drawerOpen,
				})}
			>
				{allowed && (<>
					<TicketHeader loading={loading}>
						<TicketInfo
							contact={contact}
							ticket={ticket}
							onClick={handleDrawerOpen}
						/>
						<TicketActionButtons ticket={ticket} />
					</TicketHeader>
					<ReplyMessageProvider>
						<MessagesList
							ticketId={ticketId}
							isGroup={ticket.isGroup}
						></MessagesList>
						<MessageInput ticketStatus={ticket.status} className={classes.input} />
					</ReplyMessageProvider>
				</>
				)}
				{!allowed && (<>
					<PermissionDenied name="Ticket" />
				</>)}
			</Paper>

			<ContactDrawer
				open={drawerOpen}
				handleDrawerClose={handleDrawerClose}
				contact={contact}
				loading={loading}
			/>
		</div>
	);
};

export default Ticket;